import React, { useContext } from "react";
import styles from "./index.module.scss";
import dots from "../../../assets/images/dots.png";
import playBtn from "../../../assets/images/playBtnn.png";
import exclusiveIcon from "../../../assets/images/exclusiveicon.png";
import premiumIcon from "../../../assets/images/premiumicon.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import {
  formatSpeakerName,
  getURLParam,
  isNewTagShow,
  secondsToHms,
  transformSearchFilterArray,
  trimString,
  updateSearchParamsForSeason,
  updateSearchParamsInURL,
} from "../../../utils/common.utils";

import { MediaMetadataContext } from "../../../contexts/MediaMetaDataContext/MediaMetadataContextProvider";
import { Box, Shimmer } from "../../../components/ShimmerEffect";
import { isMobileDevice } from "../../../utils/getPlatformInfo";
import { VIDEO_CLICK } from "../../../AppConstants/AnalyticsEventName";
import { AnalyticsContext } from "../../../contexts/Analytics/AnalyticsContextProvider";
import {
  ContentType,
  ExclusiveType,
  VideoType,
} from "../../../AppConstants/TypeConstants";

import { ConstantDataContext } from "../../../contexts/ConstantContext/ConstantDataContextProvider";
// import { connectHitInsights } from "react-instantsearch-dom";
import {
  algoliaCustomEvents,
  AlgoliaEventType,
  algoliaIndices,
} from "../../../AppConstants/AlgoliaSearchConstants";
import { useLocation } from "react-router-dom";
import { URL_PARAM_TAG } from "../../../AppConstants/URLParam";
import { SEARCH_RESULT_EVENT } from "../../../Clevertap/EventNames.ts";
import { getSearchClickItemData } from "../../../Clevertap/EventHandler.ts";
import { VIDEO_PAGE_ROUTE } from "../../../AppConstants/Routes.js";

const MINI_VIDEO_COUNT = 5;
export const SEARCH_PAGE_CONST = {
  series: { heading: "Series", redirectTo: "series" },
  academy: { heading: "Academy", redirectTo: "academy" },
  videos: { heading: "Videos", redirectTo: "video" },
  webinar: { heading: "Webinars", redirectTo: "webinar" },
};

const VideoCard = ({
  index,
  video,
  expandable,
  expanded,
  videosLength,
  toggleWrapper,
  pageToRedirect,
  fetchParameters,
  watchedPercentage,
  loading,
  insights,
  inputValue,
  appliedFilters
}) => {
  const {
    TagsData: TAGS_DATA,
    setShowSearch,
    setSelectedShow,
    globalDocflixConstant,
  } = useContext(ConstantDataContext);

  const { addGAWithUserInfo, addCleverTapAnalytics, SearchEvent } = useContext(AnalyticsContext);
  const history = useHistory();
  const { pathname, search = "", hash = "", state } = useLocation();

  const handleMouseHover = (e) => {
    if (video.playbackid) {
      e.target.src = `https://image.mux.com/${video.playbackid}/animated.gif${video.tokens && video.tokens.gifToken && video.tokens.gifToken !== ""
        ? `?token=${video.tokens.gifToken}`
        : ``
        }`;
    }
  };
  const handleMouseLeave = (e) => {
    if (video.playbackid) {
      e.target.src = video.thumbnailUrl
        ? video.thumbnailUrl
        : `https://image.mux.com/${video.playbackid}/thumbnail.jpg${video.tokens &&
          video.tokens.thumbnailToken &&
          video.tokens.thumbnailToken !== ""
          ? `?token=${video.tokens.thumbnailToken}`
          : ``
        }`;
    }
  };

  const redirectToVideoDetailPage = (item, index) => {
    addCleverTapAnalytics(SEARCH_RESULT_EVENT, { ...transformSearchFilterArray(appliedFilters), ...getSearchClickItemData({ index: index, "Search Term": inputValue, ...item }) })
    if (item.type === SEARCH_PAGE_CONST.series.heading) {
      history.push({
        pathname: `/series/${item.id}`,
        state: { search: video.__queryID, positions: video.__position },
        search: updateSearchParamsForSeason(
          search,
          "ep",
          parseInt(item.seasonNumber) + 1,
          1
        ),
      });
    } else if (item.type === SEARCH_PAGE_CONST.academy.heading) {
      history.push({
        pathname: `/academy/${item.id}`,
        state: { search: video.__queryID, positions: video.__position },
        search: search,
      });
    } else if (item.type === SEARCH_PAGE_CONST.webinar.heading) {
      history.push({
        pathname: `/webinar/${item.id}`,
        state: { search: video.__queryID, positions: video.__position },
        search: search,
      });
    } else {
      history.push({
        pathname: `/video/${item.id}`,
        state: { search: video.__queryID, positions: video.__position },
        search: search,
      });
    }
    setSelectedShow("");
    setShowSearch(false);
  };

  return (
    <Shimmer
      loading={loading}
      loadingComponent={
        <Box
          height={200}
          width={isMobileDevice ? "50%" : "24%"}
          marginRight={24}
        ></Box>
      }
      renderComponent={
        <div
          className={`${styles.card} ${video.portraitThumbnailUrl && styles.portraitCard
            }`}
          key={index}
        >
          <div
            onClick={() => {
              // if (
              //   videosLength > MINI_VIDEO_COUNT
              //     ? index !== MINI_VIDEO_COUNT
              //     : true
              // ) {
              addGAWithUserInfo(VIDEO_CLICK, { eventId: video.id });
              redirectToVideoDetailPage(video, index);

              //  TODO:  please add this - --- video.__queryID
              SearchEvent(
                AlgoliaEventType.Click,
                algoliaIndices.videos,
                algoliaCustomEvents.searchClick,
                [video.id],
                video.__queryID,
                video.__position
              );

              // insights("clickedObjectIDsAfterSearch", {
              //   userToken: "3a3aOXfEcHgXTsbKUpb5B3zbg6G2",
              //   index: "video_speakers",
              //   eventName: "click",
              //   objectIDs: ["video-l51763vc", "video-l516kkw5"],
              // });
            }}
            className={styles.videoCard}
            style={{
              marginBottom: expandable && expanded ? "42px" : "2rem",
            }}
          >
            <div
              className={styles.thumbImg}
              style={{
                position: "relative",
              }}
            >
              <img
                src={
                  video.portraitThumbnailUrl
                    ? video.portraitThumbnailUrl
                    : video.thumbnailUrl
                }
                alt={video.title}
                className={styles.videoThumb}
              />
              {/* <img src={playBtn} alt="playBtn" className={styles.playBtn} /> */}
              {video.type && video.type !== ExclusiveType.FREE && (
                <img
                  src={
                    video.type === ExclusiveType.PREMIUM
                      ? premiumIcon
                      : video.type === ExclusiveType.EXCLUSIVE
                        ? exclusiveIcon
                        : ""
                  }
                  alt={
                    video.type === ExclusiveType.PREMIUM
                      ? ExclusiveType.PREMIUM
                      : video.type === ExclusiveType.EXCLUSIVE
                        ? ExclusiveType.EXCLUSIVE
                        : ""
                  }
                  className={styles.videoType}
                />
              )}
              <div
                className={"videoTag"}
                style={{
                  opacity: isNewTagShow(globalDocflixConstant, video, true),
                }}
              >
                {video?.latestTag?.text ||
                  globalDocflixConstant?.videos?.latestTag?.text ||
                  "NEW"}
              </div>
              {index === MINI_VIDEO_COUNT && !expanded ? (
                expandable ? (
                  <>
                    {videosLength > MINI_VIDEO_COUNT && (
                      <div
                        onClick={() => toggleWrapper()}
                        className={`${styles.countCard} animate__animated animate__fadeIn`}
                      >
                        +{videosLength - MINI_VIDEO_COUNT} Videos
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {videosLength > MINI_VIDEO_COUNT && (
                      <Link
                        className={`${styles.countCard} animate__animated animate__fadeIn`}
                        to={{
                          pathname: pageToRedirect,
                          // search: getURLParam([
                          //   {
                          //     name: "tag",
                          //     value: fetchParameters.tags[0],
                          //   },
                          // ]),
                          search: updateSearchParamsInURL(
                            search,
                            URL_PARAM_TAG,
                            fetchParameters.tags[0]
                          ),
                          hash: TAGS_DATA[fetchParameters.tags[0]]
                            ? TAGS_DATA[fetchParameters.tags[0]].sectionId
                            : "tagSection",
                          state: { preExpanded: true },
                        }}
                      >
                        +{videosLength - MINI_VIDEO_COUNT}
                      </Link>
                    )}
                  </>
                )
              ) : null}
            </div>
            {index !== MINI_VIDEO_COUNT ||
              expanded ||
              (index === MINI_VIDEO_COUNT && videosLength <= MINI_VIDEO_COUNT) ? (
              <>
                {/* <div className={styles.durationBar}>
                  <div
                    style={{
                      width: `${watchedPercentage * 100}%`,
                      backgroundColor: "#EB4547",
                      position: "absolute",
                      height: "6px",
                      borderRadius: "4px",
                    }}
                  />
                </div> */}
                <div className={styles.rowData}>
                  {isMobileDevice ? (
                    <div className={styles.desc}>
                      <div>
                        <div className={styles.title}>
                          {video.videoType === VideoType.VIDEO_STANDALONE
                            ? video.title
                            : video.seriesData?.seriesName}
                          {"  "}
                          <div className={styles.seasonEpisodeColor}>
                            {video.videoType === VideoType.VIDEO_SERIES
                              ? `S${video.videoData.seasonIndex + 1} ● E${video.videoData.episodeId
                              }`
                              : video.videoType === VideoType.TRAILER
                                ? `S${video.videoData.seasonIndex + 1}`
                                : ""}
                          </div>
                        </div>

                        <div className={styles.description}>
                          {trimString(video.description, 50)}
                          {/* {video.videoType === VideoType.VIDEO_STANDALONE
                            ? trimString(video.description, 50)
                            : trimString(video.title, 50)} */}
                        </div>
                      </div>
                      {video?.speakernames && (
                        <div className={styles.speakerNames}>
                          {formatSpeakerName(video.speakernames)}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {video.videoType === VideoType.VIDEO_SERIES
                        ? `E${video.videoData.episodeId} : ${video.title}`
                        : video.videoType === VideoType.TRAILER
                          ? `T : ${video.title}`
                          : video.title}
                    </div>
                  )}

                  {/* <div>
                    <img src={dots} alt="menu" />
                  </div> */}
                </div>
              </>
            ) : null}
          </div>
        </div>
      }
    />
  );
};

const Hits = ({ inputValue, appliedFilters, title, results }) => {
  const { mediaMetadata } = useContext(MediaMetadataContext);

  return (
    <div className={styles.videosContainer}>
      <h5>{title}</h5>
      {results.length > 0 ? (
        <>
          <div className={styles.videosWrapper}>
            {results &&
              results.length > 0 &&
              results.map((result, index, insights) => (
                <VideoCard
                  insights={insights}
                  key={result.id}
                  index={index}
                  video={result}
                  appliedFilters={appliedFilters}
                  inputValue={inputValue}
                  expanded={true}
                  videosLength={results.length}
                  //   toggleWrapper={toggleWrapper}
                  pageToRedirect={VIDEO_PAGE_ROUTE}
                  fetchParameters={result}
                  watchedPercentage={
                    mediaMetadata[result.id]
                      ? mediaMetadata[result.id].lastWatchTimestamp <=
                        mediaMetadata[result.id].totalDuration
                        ? mediaMetadata[result.id].lastWatchTimestamp /
                        mediaMetadata[result.id].totalDuration
                        : 1
                      : 0
                  }
                />
              ))}
          </div>
        </>
      ) : (
        <div className={styles.emptyList}>No videos</div>
      )}
    </div>
  );
};

export default Hits;
