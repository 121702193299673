import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { isMobileDevice } from "../../utils/getPlatformInfo";
import brandLogo from "../../assets/images/brandLogo.png";
import close from "../../assets/images/close.png";

function updateStatusINSessionStorage() {
  window.sessionStorage.setItem("app_redirect_modal", true);
}

const AppInstallPopUp = () => {
  const [showToast, setShowNetworkToast] = useState(false);

  useEffect(() => {
    let status = window.sessionStorage.getItem("app_redirect_modal");
    if (!status || status != "true") {
      setShowNetworkToast(true);
    }
  }, []);

  const handleClose = () => {
    setShowNetworkToast(false);
    updateStatusINSessionStorage();
  };

  const handleGetAppButton = () => {
    window.open("https://share.docflix.com/byAN");
    updateStatusINSessionStorage();
  };

  return isMobileDevice && showToast ? (
    <div className={`${styles.container}`}>
      <div className="animate__animated animate__fadeInUp">
        <div className={styles.box}>
          {/* <div>
            <p className={styles.title}>
              <img src={brandLogo} alt="Docflix" /> Docflix is better on the app
            </p>
            <p className={styles.desc}>
              Now stream comfortably on your phone or tablet
            </p>
          </div> */}

          <div className={styles.btnContainer}>
            {/* <p
              className={`${styles.btn} ${styles.close}`}
              role="button"
              onClick={handleClose}
            >
              Not now
            </p> */}
            <img src={close} className={styles.closeIcon} onClick={handleClose} />
            <p className={styles.title}>
              {/* <img src={brandLogo} alt="Docflix" />  */}
              Docflix is better on the app
            </p>
            <p
              className={`${styles.btn} ${styles.getApp}`}
              onClick={handleGetAppButton}
              role="button"
            >
              GET APP
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppInstallPopUp;
